const translation = {
    serviceAccounts: {
        title: '組織一覧'
    },
    serviceAccount: '組織',
    serviceAccountDetail: {
        conversationNumber: '会話数',
        azureNamespaces: 'データラベル',
        editUserNumber: '編集ユーザー数',
        dbEditorNumber: 'DBエディター数',
        pineconeQueryCount: 'Pineconeクエリ数',
        guideline: '組織をクリックして詳細を表示',
        limit: '{{type}} の制限に達しました',
        limitDescription: '組織の制限に達しました'
    },
    serviceAccountCard: {
        serviceAccountId: 'ID: {{id}}'
    },
    form: {
        title: {
            add: '組織を追加',
            edit: '組織を編集',
            delete: '組織を削除',
            search: '名前を検索'
        },
        field: {
            serviceAccount: {
                name: '組織名',
                placeholder: '組織名を入力してください'
            },
            numberConversation: {
                name: '月間最大会話数',
                placeholder: '月間最大会話数を入力してください'
            }
        },
        description: {
            deletedConfirm: 'この組織を削除してもよろしいですか？'
        }
    },
    validation: {
        message: '組織名に特殊文字を含めることはできません',
        serviceAccountRequired: '組織名は必須です',
        numberConversationRequired: '会話の最大数は必須です',
        max: '名前は50文字以内である必要があります',
        min: '0 より大きい数値を入力してください',
        limitConversation: '1,000,000 以下の数値を入力してください',
        notEmpty: '組織名は空にできません'
    },
    message: {
        createdSuccess: '組織を正常に作成しました',
        createdFailed: '組織の作成に失敗しました: {{errorMessage}}',
        updatedSuccess: '組織を正常に更新しました',
        deletedPending: '組織を削除中',
        deletedSuccess: '組織は正常に削除されました',
        deletedFailed: '組織の削除に失敗しました'
    }
}

export default translation
