const translation = {
    serviceAccounts: {
        title: 'List organizations'
    },
    serviceAccount: 'Organization',
    serviceAccountDetail: {
        conversationNumber: 'Number of Conversation',
        azureNamespaces: 'Data Labels',
        editUserNumber: 'Number of Edit User',
        dbEditorNumber: 'Number of DB Editor',
        pineconeQueryCount: 'Pinecone Query Count',
        guideline: 'Click on a organization to view details',
        limit: 'Maximum of {{type}} reached',
        limitDescription: 'Organization got the limitation'
    },
    serviceAccountCard: {
        serviceAccountId: 'ID: {{id}}'
    },
    form: {
        title: {
            add: 'Add organization',
            edit: 'Edit organization',
            delete: 'Delete organization',
            search: 'Search name'
        },
        field: {
            serviceAccount: {
                name: 'Organization name',
                placeholder: 'Enter organization name'
            },
            numberConversation: {
                name: 'Maximum number of conversation per month',
                placeholder: 'Enter maximum number of conversation per month'
            }
        },
        description: {
            deletedConfirm: 'Are you sure you want to delete this organization?'
        }
    },
    validation: {
        message: 'The Organization name cannot contain special characters',
        serviceAccountRequired: 'Organization name is required',
        numberConversationRequired: 'Number of conversation is required',
        max: 'Name must be at most 50 characters',
        notEmpty: 'Organization name cannot be empty',
        min: 'Please input a number > 0',
        limitConversation: 'Please input a number <= 1.000.000'
    },
    message: {
        createdSuccess: 'Created organization successfully',
        createdFailed: 'Failed to create organization: {{errorMessage}}',
        updatedSuccess: 'Updated organization successfully',
        deletedPending: 'Deleting organization',
        deletedSuccess: 'Organization deleted successfully',
        deletedFailed: 'Failed to delete organization'
    }
}

export default translation
