const translation = {
    chains: {
        conversationalRetrievalQAChain: {
            label: '会話型検索 QA チェーン',
            description: 'ドキュメント QA - RetrievalQAChain を基に、チャット履歴コンポーネントを提供します',
            input: {
                chatModel: {
                    label: 'チャットモデル'
                },
                vertorStoreRetriever: {
                    label: 'ベクトルストアリトリーバー'
                },
                quickReply: {
                    label: 'クイックリプライ'
                },
                chainName: {
                    label: 'チェーン名',
                    placeholder: 'チェーンの名前を入力'
                },
                returnSourceDocuments: {
                    label: 'ソースドキュメントを返す'
                },
                repharasePrompt: {
                    label: 'プロンプトの言い換え',
                    description: '以前のチャット履歴を使用して、latest_user_input を独立した最新のユーザー入力に言い換えます',
                    warning: 'プロンプトには入力変数 {chat_history} および {latest_user_input} が含まれている必要があります'
                },
                responsePrompt: {
                    label: '応答プロンプト',
                    description: '言い換えた質問を受け、提供されたコンテキストから回答を検索します',
                    warning: 'プロンプトには入力変数 {context} が含まれている必要があります'
                }
            }
        }
    },
    credential: {
        label: 'Azure OpenAI API',
        description:
            'Azure OpenAI サービスの使い方については、<a target="_blank" href="https://azure.microsoft.com/ja-jp/products/cognitive-services/openai-service">公式ガイド</a>を参照してください。',
        input: {
            azureApiKey: {
                label: 'Azure OpenAI API キー',
                description:
                    'Azure OpenAI で API キーを作成する方法については、<a target="_blank" href="https://learn.microsoft.com/ja-jp/azure/cognitive-services/openai/quickstart?tabs=command-line&pivots=rest-api#set-up">公式ガイド</a>を参照してください。'
            },
            azureApiInstance: {
                label: 'Azure OpenAI API インスタンス名',
                placeholder: 'YOUR-INSTANCE-NAME'
            },
            azureApiDeployment: {
                label: 'Azure OpenAI API デプロイメント名',
                placeholder: 'YOUR-DEPLOYMENT-NAME'
            },
            azureApiVersion: {
                label: 'Azure OpenAI API バージョン',
                placeholder: '2023-06-01-preview',
                description:
                    'サポートされている API バージョンの説明については、<a target="_blank" href="https://learn.microsoft.com/ja-jp/azure/cognitive-services/openai/reference#chat-completions">例</a>を参照してください。'
            }
        }
    },
    llmChain: {
        label: 'LLM チェーン',
        description: 'LLM に対してクエリを実行するチェーン',
        input: {
            languageModel: {
                label: '言語モデル'
            },
            prompt: {
                label: 'プロンプト'
            },
            quickReply: {
                label: 'クイックリプライ'
            },
            chainName: {
                label: 'チェーン名',
                placeholder: 'チェーンの名前を入力'
            }
        },
        output: {
            llmChain: {
                label: 'LLM チェーン'
            },
            outputPrediction: {
                label: '出力予測'
            }
        }
    },
    azureChatOpenAI: {
        label: 'Azure ChatOpenAI',
        description: 'Chat エンドポイントを使用する Azure OpenAI 大規模言語モデルのラッパー',
        input: {
            modelName: {
                label: 'モデル名'
            },
            temperature: {
                label: '温度'
            }
        }
    },
    chatGoogleGenerativeAI: {
        label: 'Chat Google Generative AI',
        description: 'Chat エンドポイントを使用する Google AI 大規模言語モデルのラッパー',
        input: {
            modelName: {
                label: 'モデル名'
            },
            temperature: {
                label: '温度'
            }
        }
    },
    chatLocalAI: {
        label: 'ChatLocalAI',
        description: 'LocalAI を使用して llama.cpp や gpt4all などのローカル LLM を使用',
        input: {
            cache: {
                label: 'キャッシュ'
            },
            basePath: {
                label: 'ベースパス'
            },
            modelName: {
                label: 'モデル名'
            },
            temperature: {
                label: '温度'
            },
            maxTokens: {
                label: '最大トークン数'
            },
            topProbability: {
                label: 'トップ確率'
            },
            timeout: {
                label: 'タイムアウト'
            }
        }
    },
    plainText: {
        label: '手動入力',
        description: '手動入力からデータを読み込む',
        input: {
            text: {
                label: 'テキスト',
                placeholder:
                    'ロレム イプサム ドロール シット アメット、コンセクテツル アディピスシング エリト、セド ド イウシモド テンポル インシディダント ウト ラボレ エト ドローレ マグナ アリクア...'
            },
            textSplitter: {
                label: 'テキスト分割器'
            },
            title: {
                label: 'タイトル'
            }
        },
        output: {
            document: {
                label: 'ドキュメント'
            },
            text: {
                label: 'テキスト'
            }
        }
    },
    importFile: {
        label: 'ファイルをインポート',
        description: 'インポートされたファイルからデータを読み込む',
        input: {
            text: {
                label: 'テキスト',
                placeholder:
                    'ロレム・イプサム・ドロール・シット・アメット、コンセクテトゥル・アディピシシング・エリット、セド・ド・エイウスモド・テンポル・インジディダント・ウット・ラボレ・エト・ドローレ・マグナ・アリクア...'
            },
            textSplitter: {
                label: 'テキストスプリッター'
            },
            title: {
                label: 'タイトル'
            },
            url: {
                label: 'ファイルURL'
            }
        },
        output: {
            document: {
                label: 'ドキュメント'
            },
            text: {
                label: 'テキスト'
            }
        }
    },
    azureEmbedding: {
        label: 'Azure OpenAI 埋め込み',
        description: '与えられたテキストに対して埋め込みを生成する Azure OpenAI API',
        credential: {
            label: 'クレデンシャルを接続'
        },
        input: {
            batchSize: {
                label: 'バッチサイズ'
            },
            timeout: {
                label: 'タイムアウト'
            }
        }
    },
    promptTemplate: {
        label: 'プロンプトテンプレート',
        description: 'LLM 用の基本的なプロンプトを表すスキーマ',
        input: {
            template: {
                label: 'テンプレート',
                placeholder: '製品名に最適な会社名は何ですか？'
            },
            formatPromptValues: {
                label: 'プロンプト値のフォーマット'
            }
        }
    },
    recursiveTextSplitter: {
        label: '再帰的テキスト分割器',
        description: `文書を異なる文字で再帰的に分割 - 最初は "\\n\\n"、次に "\\n"、最後に " "`,
        input: {
            promptValues: {
                label: '入力'
            },
            retrievalOptions: {
                label: '取得オプション',
                description: '取得のための提案オプション',
                options: {
                    sentencexMeaningOfEachWord: {
                        label: '文 × 各単語の意味',
                        description: 'チャンクサイズ 100 - 300; チャンク重複: 0'
                    },
                    sentencexContextOfSentenceUnit: {
                        label: '文 × 文単位のコンテキスト',
                        description: 'チャンクサイズ 500 - 4000; チャンク重複: チャンクサイズの 10-20%'
                    },
                    structuredDescriptionxMeaningOfEachWord: {
                        label: '構造化説明 × 各単語の意味',
                        description: 'チャンクサイズ 50 - 300; チャンク重複: 0'
                    }
                }
            },
            chunkSize: {
                label: 'チャンクサイズ',
                validationSchema: {
                    typeError: {
                        props: '数値を指定する必要があります。'
                    },
                    positive: {
                        props: '正の数値を入力してください。'
                    }
                }
            }
        }
    },
    semanticTextSplitter: {
        label: 'セマンティックテキスト分割器',
        description: `セマンティック埋め込みに基づいてコンテンツを文書に分割します`,
        input: {
            breakpointThresholdType: {
                label: '分割しきい値の種類',
                description: 'データをチャンクに分割するためのしきい値の種類',
                options: {
                    percentile: {
                        label: 'パーセンタイル',
                        description: 'データを順位でグループ化し、上位の割合に注目します（しきい値範囲: 80%-99%、デフォルト: 95%）'
                    },
                    standardDeviation: {
                        label: '標準偏差',
                        description: '平均から大きく離れたデータを分離し、極端な違いを強調します（しきい値範囲: 1.5-3.0、デフォルト: 3.0）'
                    },
                    interquartile: {
                        label: '四分位範囲',
                        description: '極端な値を無視しつつ、中間範囲のデータに焦点を当てます（しきい値範囲: 1.5-3.0、デフォルト: 1.5）'
                    },
                    gradient: {
                        label: '勾配',
                        description: 'データポイント間の重要な変化を識別して境界を定義します（しきい値範囲: 80%-99%、デフォルト: 95%）'
                    }
                }
            },
            breakpointThresholdAmount: {
                label: '分割しきい値の量',
                description: 'チャンクを分割するためのしきい値の量'
            }
        }
    },
    documentIntelligenceSplitter: {
        label: 'ドキュメントインテリジェンス分割器',
        description: `分割されたセクションに基づいて、解析結果を文書に分割します。`,
        inputs: {
            maxLength: {
                label: '最大長',
                description: '1つのチャンクの最大長'
            }
        }
    },
    regularExpressionExtraction: {
        label: '変数定義',
        description: '変数定義を実行する',
        input: {
            table: {
                label: 'テーブル'
            }
        },
        output: {
            regularExpressionExtraction: {
                label: '変数定義'
            }
        }
    },
    azureIndex: {
        label: 'Azure Index',
        description:
            'Azure Indexを使用して埋め込まれたデータをアップサートし、最先端の完全管理型ホスティングベクトルデータベースで類似性ハイブリッド検索を実行します。',
        input: {
            promptValues: {
                label: '入力'
            },
            namespace: {
                label: 'ネームスペース'
            },
            topK: {
                label: 'トップ K',
                description: '取得するトップ結果の数。デフォルトは 4'
            },
            searchMethod: {
                label: '検索方式',
                description:
                    'Azure Indexで検索する方法には、fullText | vector | hybridSemanticが含まれます。デフォルトはHybrid Semanticです。',
                options: {
                    fullText: {
                        label: 'フルテキスト検索'
                    },
                    vector: {
                        label: 'ベクトル検索'
                    },
                    hybrid: {
                        label: 'ハイブリッド検索'
                    },
                    semantic: {
                        label: 'セマンティック検索'
                    },
                    hybridSemantic: {
                        label: 'セマンティックハイブリッド検索'
                    }
                }
            }
        },
        output: {
            retriever: {
                label: 'Azure インデックス リトリーバー'
            }
        }
    },
    pinecone: {
        label: 'Pinecone',
        description:
            '埋め込みデータをアップサートし、Pinecone を使用してクエリに基づいて類似性検索を実行します。Pinecone は完全に管理されたホスト型ベクトルデータベースのリーディングプロバイダーです。',
        input: {
            promptValues: {
                label: '入力'
            },
            pineconeNamespace: {
                label: 'Pinecone ネームスペース'
            },
            topK: {
                label: 'トップ K',
                description: '取得するトップ結果の数。デフォルトは 4'
            }
        },
        output: {
            retriever: {
                label: 'Pinecone リトリーバー'
            }
        }
    },
    prompt: {
        rephraseTemplate: `次の会話と後続の質問に基づいて、その質問を単独の質問に言い換えてください。
      
チャット履歴:
{chat_history}
後続の入力: {latest_user_input}
単独の質問:`,
        responseTemplate: `あなたは私と会話する「ドキュメント」として振る舞ってください。あなたの名前は「AIアシスタント」です。提供されたコンテキストを使用して、可能な限り正確にユーザーの質問に答えてください。
コンテキストに質問に関連する情報がない場合は、「うーん、ちょっと分かりません」とだけ言って、それ以上は何も答えないでください。関連のない質問には一切答えないでください。決してキャラクターを崩さないでください。
------------
{context}
------------
注意：コンテキストに関連する情報がない場合は、「うーん、ちょっと分かりません」と言ってください。答えを作ろうとしないでください。決してキャラクターを崩さないでください。`
    },
    transitionRule: {
        label: '条件分岐',
        description: 'If Else JavaScript 関数に基づいてフローを分割'
    }
}

export default translation
