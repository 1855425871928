import axios from 'axios'
import { baseAuthURL } from 'store/constant'
import {
    clearLS,
    getAccessTokenFromLS,
    getRefreshTokenFromLS,
    isAxiosExpiredTokenError,
    isAxiosUnauthorizedError,
    setAccessTokenToLS
} from 'utils/authorization-interceptor'
import { enqueueSnackbar as enqueueSnackbarAction } from 'store/actions'
import { store } from 'store'

const config = {
    baseURL: `${baseAuthURL}`
}
const AUTHENTICATION_TYPE = `Bearer`
const URL_REFRESH_TOKEN = '/account/api/refresh-token'

class Http {
    constructor() {
        this.refreshTokenRequest = null
        this.isPersistAuthorization = true
        this.instance = axios.create({
            baseURL: config.baseURL,
            // timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })

        this.instance.interceptors.request.use(
            (config) => {
                if (config.headers.removeAuthenticate) {
                    delete config.headers.Authorization
                    delete config.headers.removeAuthenticate
                    return config
                }

                const accessToken = getAccessTokenFromLS()

                if (accessToken && config.headers && this.isPersistAuthorization) {
                    config.headers.authorization = `${AUTHENTICATION_TYPE} ${accessToken}`
                } else {
                    delete config.headers.Authorization
                }

                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        this.instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (isAxiosUnauthorizedError(error)) {
                    const config = error.response?.config || { headers: {}, url: '' }
                    const { url } = config

                    if (isAxiosExpiredTokenError(error) && url !== URL_REFRESH_TOKEN) {
                        this.refreshTokenRequest = this.refreshTokenRequest
                            ? this.refreshTokenRequest
                            : this.handleRefreshToken().finally(() => {
                                  setTimeout(() => {
                                      this.refreshTokenRequest = null
                                  }, 10000)
                                  this.isPersistAuthorization = true
                              })

                        return this.refreshTokenRequest.then((access_token) => {
                            return this.instance({
                                ...config,
                                headers: { ...config.headers, authorization: access_token }
                            })
                        })
                    }

                    clearLS()
                    const message = error.response?.data?.message || error.message
                    store.dispatch(enqueueSnackbarAction({ message, options: { variant: 'error' } }))
                    window.location.replace('/login')
                }
                return Promise.reject(error)
            }
        )
    }

    async handleRefreshToken() {
        this.isPersistAuthorization = false
        const refreshToken = getRefreshTokenFromLS()
        try {
            const res = await this.instance.post(URL_REFRESH_TOKEN, {
                refresh: refreshToken
            })
            const { access_token } = res.data
            setAccessTokenToLS(access_token)
            return access_token
        } catch (error) {
            clearLS()
            throw error
        }
    }
}

const apiAuth = new Http().instance
export default apiAuth
