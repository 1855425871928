const translation = {
    action: {
        addNew: 'Add New',
        add: 'Add',
        create: 'Create',
        createNew: 'Create New',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        update: 'Update',
        save: 'Save',
        saveChange: 'Save Change',
        saveLoader: 'Save Loader',
        saveChunks: 'Save Chunks',
        show: 'Show',
        copy: 'Copy',
        loading: 'Loading',
        back: 'Back',
        skip: 'Skip',
        close: 'Close',
        yes: 'Yes',
        no: 'No',
        process: 'Process',
        preview: 'Preview',
        log: 'Log',
        clear: 'Clear',
        expand: 'Expand',
        info: 'Info',
        dupplicate: 'Dupplicate',
        view: 'View',
        import: 'Import',
        ok: 'OK'
    },
    status: {
        empty: 'EMPTY',
        sync: 'SYNC',
        syncing: 'SYNCING',
        stale: 'STALE',
        new: 'NEW',
        synced: 'SYNCED'
    },
    message: {
        nodeOutdated: 'Node version {{oldVersion}} outdated\nUpdate to latest version {{newVersion}}',
        nodeVersionEmpty: 'Node outdated\nUpdate to latest version {{newVersion}}',
        nodeDeprecating: 'This node will be deprecated in the next release. Update to a new node tagged with NEW'
    },
    pagination: {
        page: 'page'
    },
    text: {
        all: 'All',
        none: 'None',
        imageUrl: 'Image URL',
        customImageUrl: 'Custom image urls',
        url: 'URL',
        customUrl: 'Custom urls',
        metadata: 'Metadata'
    },
    placeholder: {
        inputTitle: 'Input Title',
        inputImageUrl: 'Input Image URL',
        inputUrl: 'Input URL',
        noOptions: 'No options'
    }
}

export default translation
