const translation = {
    pageName: 'ユーザーリスト',
    table: {
        header: {
            id: 'ID',
            firstName: '名前',
            lastName: '苗字',
            email: 'メールアドレス',
            serviceAccount: '組織',
            createdDate: '作成日',
            role: '役割',
            action: '操作'
        }
    },
    form: {
        search: {
            placeholder: '名前または苗字で検索'
        },
        delete: {
            title: '削除',
            confirmMessage: 'このユーザーを削除してもよろしいですか？'
        },
        view: {
            title: 'ユーザー情報',
            field: {
                serviceAccount: '組織',
                accountType: 'アカウントタイプ',
                firstName: '名前',
                lastName: '苗字',
                email: 'メールアドレス',
                createdDate: '作成日',
                role: '役割'
            },
            placeholder: {
                firstName: '名前を入力',
                lastName: '苗字を入力',
                email: 'メールアドレスを入力'
            }
        },
        update: {
            title: '組織を更新'
        },
        serviceAccount: {
            editSA: '組織を編集',
            addSA: '組織に追加',
            createUser: 'ユーザーが作成されました',
            addUser: '新しいユーザーを追加',
            editUser: 'ユーザーを編集',
            select: '組織を選択',
            createdMessage: 'デフォルトのパスワードでユーザーを作成しました:'
        },
        resetPassword: {
            title: 'パスワードをリセット',
            confirmMessage: 'このユーザーのパスワードをリセットしますか？',
            newPassword: '新しいパスワードは：',
            copyPassword: 'パスワードをコピー'
        }
    },
    message: {
        deletedSuccess: '削除に成功しました。ユーザーは現在無効です。',
        deletedFailed: 'ユーザーの削除に失敗しました: {{error}}',
        updatedSuccess: '{{email}} の組織を更新しました',
        addedUserSuccess: 'ユーザーが正常に追加されました',
        updatedUserSuccess: 'ユーザーが正常に更新されました',
        resetPasswordSuccess: 'パスワードが正常にリセットされました',
        resetPasswordFailed: 'パスワードのリセットに失敗しました',
        copyPasswordSuccess: 'パスワードがクリップボードにコピーされました',
        copyPasswordFailed: 'パスワードのコピーに失敗しました'
    },
    validation: {
        firstName: {
            notSpecialCharNum: '名前に特殊文字や数字を含めることはできません。',
            notEmpty: 'このフィールドは空白にできません。',
            max: '名前は254文字以内である必要があります。',
            required: 'このフィールドは空白にできません。'
        },
        lastName: {
            notSpecialCharNum: '苗字に特殊文字や数字を含めることはできません。',
            notEmpty: 'このフィールドは空白にできません。',
            max: '苗字は254文字以内である必要があります。',
            required: 'このフィールドは空白にできません。'
        },
        email: {
            notEmpty: 'このフィールドは空白にできません。',
            correctFormat: 'メールは正しい形式で入力する必要があります。',
            required: 'このフィールドは空白にできません。'
        },
        role: {
            notEmpty: '役割を選択してください。',
            required: 'このフィールドは空白にできません。'
        }
    },
    role: {
        Admin: '管理者',
        'Edit User': 'ユーザー編集',
        'DB Editor': 'データベースエディター'
    }
}

export default translation
