const translation = {
    chains: {
        conversationalRetrievalQAChain: {
            label: 'Conversational Retrieval QA Chain',
            description: 'Document QA - built on RetrievalQAChain to provide a chat history component',
            input: {
                chatModel: {
                    label: 'Chat Model'
                },
                vertorStoreRetriever: {
                    label: 'Vector Store Retriever'
                },
                quickReply: {
                    label: 'Quick Reply'
                },
                chainName: {
                    label: 'Chain Name',
                    placeholder: 'Name Your Chain'
                },
                returnSourceDocuments: {
                    label: 'Return Source Documents'
                },
                repharasePrompt: {
                    label: 'Rephrase Prompt',
                    description: 'Using previous chat history, rephrase latest_user_input into a standalone latest_user_input',
                    warning: 'Prompt must include input variables: {chat_history} and {latest_user_input}'
                },
                responsePrompt: {
                    label: 'Response Prompt',
                    description: 'Taking the rephrased question, search for answer from the provided context',
                    warning: 'Prompt must include input variable: {context}'
                }
            }
        }
    },
    credential: {
        label: 'Azure OpenAI API',
        description:
            'Refer to <a target="_blank" href="https://azure.microsoft.com/en-us/products/cognitive-services/openai-service">official guide</a> of how to use Azure OpenAI service',
        input: {
            azureApiKey: {
                label: 'Azure OpenAI Api Key',
                description:
                    'Refer to <a target="_blank" href="https://learn.microsoft.com/en-us/azure/cognitive-services/openai/quickstart?tabs=command-line&pivots=rest-api#set-up">official guide</a> on how to create API key on Azure OpenAI'
            },
            azureApiInstance: {
                label: 'Azure OpenAI Api Instance Name',
                placeholder: 'YOUR-INSTANCE-NAME'
            },
            azureApiDeployment: {
                label: 'Azure OpenAI Api Deployment Name',
                placeholder: 'YOUR-DEPLOYMENT-NAME'
            },
            azureApiVersion: {
                label: 'Azure OpenAI Api Version',
                placeholder: '2023-06-01-preview',
                description:
                    'Description of Supported API Versions. Please refer <a target="_blank" href="https://learn.microsoft.com/en-us/azure/cognitive-services/openai/reference#chat-completions">examples</a>'
            }
        }
    },
    llmChain: {
        label: 'LLM Chain',
        description: 'Chain to run queries against LLMs',
        input: {
            languageModel: {
                label: 'Language Model'
            },
            prompt: {
                label: 'Prompt'
            },
            quickReply: {
                label: 'Quick Reply'
            },
            chainName: {
                label: 'Chain Name',
                placeholder: 'Name Your Chain'
            }
        },
        output: {
            llmChain: {
                label: 'LLM Chain'
            },
            outputPrediction: {
                label: 'Output Prediction'
            }
        }
    },
    azureChatOpenAI: {
        label: 'Azure ChatOpenAI',
        description: 'Wrapper around Azure OpenAI large language models that use the Chat endpoint',
        input: {
            modelName: {
                label: 'Model Name'
            },
            temperature: {
                label: 'Temperature'
            }
        }
    },
    chatGoogleGenerativeAI: {
        label: 'Chat Google Generative AI',
        description: 'Wrapper around Google AI large language models that use the Chat endpoint',
        input: {
            modelName: {
                label: 'Model Name'
            },
            temperature: {
                label: 'Temperature'
            }
        }
    },
    chatLocalAI: {
        label: 'ChatLocalAI',
        description: 'Use local LLMs like llama.cpp, gpt4all using LocalAI',
        input: {
            cache: {
                label: 'Cache'
            },
            basePath: {
                label: 'Base Path'
            },
            modelName: {
                label: 'Model Name'
            },
            temperature: {
                label: 'Temperature'
            },
            maxTokens: {
                label: 'Max Tokens'
            },
            topProbability: {
                label: 'Top Probability'
            },
            timeout: {
                label: 'Timeout'
            }
        }
    },
    plainText: {
        label: 'Manual Submission',
        description: 'Load data from manual submission',
        input: {
            text: {
                label: 'Text',
                placeholder:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...'
            },
            textSplitter: {
                label: 'Text Splitter'
            },
            title: {
                label: 'Title'
            }
        },
        output: {
            document: {
                label: 'Document'
            },
            text: {
                label: 'Text'
            }
        }
    },
    importFile: {
        label: 'Import File',
        description: 'Load data from imported file',
        input: {
            text: {
                label: 'Text',
                placeholder:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...'
            },
            textSplitter: {
                label: 'Text Splitter'
            },
            title: {
                label: 'Title'
            },
            url: {
                label: 'File URL'
            }
        },
        output: {
            document: {
                label: 'Document'
            },
            text: {
                label: 'Text'
            }
        }
    },
    azureEmbedding: {
        label: 'Azure OpenAI Embeddings',
        description: 'Azure OpenAI API to generate embeddings for a given text',
        credential: {
            label: 'Connect Credential'
        },
        input: {
            batchSize: {
                label: 'Batch Size'
            },
            timeout: {
                label: 'Timeout'
            }
        }
    },
    promptTemplate: {
        label: 'Prompt Template',
        description: 'Schema to represent a basic prompt for an LLM',
        input: {
            template: {
                label: 'Template',
                placeholder: 'What is a good name for a company that makes {product}?'
            },
            formatPromptValues: {
                label: 'Format Prompt Values'
            }
        }
    },
    recursiveTextSplitter: {
        label: 'Recursive Character Text Splitter',
        description: `Split documents recursively by different characters - starting with "\\n\\n", then "\\n", then " "`,
        input: {
            promptValues: {
                label: 'Input'
            },
            retrievalOptions: {
                label: 'Retrieval options',
                description: 'Suggestion options for the retrieval',
                options: {
                    sentencexMeaningOfEachWord: {
                        label: 'Sentence x Meaning of each word',
                        description: 'Chunk size 100 - 300; chunk overlap: 0'
                    },
                    sentencexContextOfSentenceUnit: {
                        label: 'Sentence x Context of sentence unit',
                        description: 'Chunk size 500 - 4000; chunk overlap: 10-20% of chunk size'
                    },
                    structuredDescriptionxMeaningOfEachWord: {
                        label: 'Structured description x meaning of each word',
                        description: 'Chunk size 50 - 300; chunk overlap: 0'
                    }
                }
            },
            chunkSize: {
                label: 'Chunk Size',
                validationSchema: {
                    typeError: {
                        props: 'You must specify a number.'
                    },
                    positive: {
                        props: 'You must enter a positive number.'
                    }
                }
            }
        }
    },
    semanticTextSplitter: {
        label: 'Semantic Text Splitter',
        description: `Split your content into documents based on the semantic embeddings`,
        input: {
            breakpointThresholdType: {
                label: 'Breakpoint Threshold Type',
                description: 'Type of threshold to break data into chunks',
                options: {
                    percentile: {
                        label: 'Percentile',
                        description: ' Groups data by ranking values, focusing on top percentages (Threshold range: 80%-99%. Default: 95%)'
                    },
                    standardDeviation: {
                        label: 'Standard deviation',
                        description:
                            'Separates data far from the average, highlighting extreme differences (Threshold range: 1.5-3.0. Default: 3.0)'
                    },
                    interquartile: {
                        label: 'Interquartile',
                        description:
                            'Focuses on middle-range data while ignoring outliers at the extremes (Threshold range: 1.5-3.0. Default: 1.5)'
                    },
                    gradient: {
                        label: 'Gradient',
                        description:
                            'Identifies significant changes between data points to define boundaries (Threshold range: 80%-99%. Default: 95%)'
                    }
                }
            },
            breakpointThresholdAmount: {
                label: 'Breakpoint Threshold Amount',
                description: 'Threshold amount to split chunks'
            }
        }
    },
    documentIntelligenceSplitter: {
        label: 'Document Intelligence Splitter',
        description: `Split your Analyze Result into documents based on the devided sections`,
        inputs: {
            maxLength: {
                label: 'Max length',
                description: 'The maximum length of 1 chunk'
            }
        }
    },
    regularExpressionExtraction: {
        label: 'Regular Expression Extraction',
        description: 'Execute Regular Expression Extraction',
        input: {
            table: {
                label: 'Table'
            }
        },
        output: {
            regularExpressionExtraction: {
                label: 'Regular Expression Extraction'
            }
        }
    },
    azureIndex: {
        label: 'Azure Index',
        description:
            'Upsert embedded data and perform similarity hybrid search upon using Azure Index, a leading fully managed hosted vector database',
        input: {
            promptValues: {
                label: 'Input'
            },
            namespace: {
                label: 'Namespace'
            },
            topK: {
                label: 'Top K',
                description: 'Number of top results to fetch. Default to 4'
            },
            searchMethod: {
                label: 'Search Method',
                description: 'Method to search on Azure Index includes: fullText | vector | hybridSemantic. Default to Hybrid Semantic',
                options: {
                    fullText: {
                        label: 'Full text'
                    },
                    vector: {
                        label: 'Vector'
                    },
                    hybrid: {
                        label: 'Hybrid'
                    },
                    semantic: {
                        label: 'Semantic'
                    },
                    hybridSemantic: {
                        label: 'Hybrid Semantic'
                    }
                }
            }
        },
        output: {
            retriever: {
                label: 'Azure Index Retriever'
            }
        }
    },
    pinecone: {
        label: 'Pinecone',
        description:
            'Upsert embedded data and perform similarity search upon query using Pinecone, a leading fully managed hosted vector database',
        input: {
            promptValues: {
                label: 'Input'
            },
            pineconeNamespace: {
                label: 'Pinecone Namespace'
            },
            topK: {
                label: 'Top K',
                description: 'Number of top results to fetch. Default to 4'
            }
        },
        output: {
            retriever: {
                label: 'Pinecone Retriever'
            }
        }
    },
    prompt: {
        rephraseTemplate: `Given the following conversation and a follow up question, rephrase the follow up question to be a standalone question.

Chat History:
{chat_history}
Follow Up Input: {latest_user_input}
Standalone Question:`,
        responseTemplate: `I want you to act as a document that I am having a conversation with. Your name is "AI Assistant". Using the provided context, answer the user's question to the best of your ability using the resources provided.
If there is nothing in the context relevant to the question at hand, just say "Hmm, I'm not sure" and stop after that. Refuse to answer any question not about the info. Never break character.
------------
{context}
------------
REMEMBER: If there is no relevant information within the context, just say "Hmm, I'm not sure". Don't try to make up an answer. Never break character.`
    },
    transitionRule: {
        label: 'Transition Rule',
        description: 'Split flows based on If Else javascript functions'
    }
}

export default translation
