const translation = {
    form: {
        title: 'Login',
        field: {
            email: 'Email',
            password: 'Password'
        },
        placeholder: {
            email: 'Enter email',
            password: 'Enter password'
        },
        button: {
            login: 'Log In'
        }
    },
    message: {
        emailNotValid: 'Email not valid!',
        enterValidEmailPassword: 'Please enter valid email or password !'
    }
}

export default translation
